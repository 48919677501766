import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../../components/CTA'
import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
} from '..'
import Form from '../../components/Form'
import { Text, GallerySection, Content } from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Raised Flyover Carport | Just Patios</title>
      <meta
        name="description"
        content="Raised flyover carport builders servicing Brisbane and the Sunshine Coast. Custom designed raised flyover carports. Contact us today for an obligation free design & quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Raised Flyover Carports</h1>

      <h4>The perfect cover for when you need that extra height.</h4>
      <p>
        This design is great to cover caravans, boats or motorhomes as they have
        extra height. We have numerous ways of attaching your new carport to
        your home so please feel free to call and let us help you design your
        new area.{' '}
      </p>
      <p>
        We can give professional advice on current council regulations in
        regards to property boundaries, and assist with all approvals. Save on
        your current insurances by covering your assets with a permanent
        structure. There are so many options, why wait? Call now and lets get
        you covered!
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>
          Request Your Free Flyover Carport Quote Today!
        </Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query FlyOverCarportQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/carports/flyover" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
